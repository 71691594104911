<template>
	<div class="">
		<header>
			<ul>
				<li>
					<div class="tab-box">
						<router-link :to="setLink('')">
							<font-awesome-icon icon="book" :style="{ width: '20px', height: 'auto' }" />
							Subject
						</router-link>
					</div>
				</li>
				<li>
					<div class="tab-box">
						<router-link :to="setLink('year')">
							<font-awesome-icon icon="calendar" :style="{ width: '20px', height: 'auto' }" />
							Exam Year
						</router-link>
					</div>
				</li>
				<li>
					<div class="tab-box">
						<router-link :to="setLink('past-question')">
							<font-awesome-icon icon="book-open" :style="{ width: '20px', height: 'auto' }" />
							Past Question
						</router-link>
					</div>
				</li>
				<li>
					<div class="tab-box">
						<router-link :to="setLink('theory-question')">
							<font-awesome-icon icon="book-open" :style="{ width: '20px', height: 'auto' }" />
							Theory Past Question
						</router-link>
					</div>
				</li>
				<li>
					<div class="tab-box">
						<router-link to="/project-page">
							<font-awesome-icon icon="sign-out-alt" :style="{ width: '20px', height: 'auto' }" />
							Back To Project
						</router-link>
					</div>
				</li>
			</ul>
		</header>
		<nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;); margin-top: 90px; position: fixed; width: 100%; top: 0px; z-index: 1000;" aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">LFX APP</li>
				<li class="breadcrumb-item">Project</li>
				<li class="breadcrumb-item">WAEC</li>
				<li class="breadcrumb-item">{{ $route.name }}</li>
			</ol>
		</nav>
		<div class="content-wrapper">
			<router-view />
		</div>
	</div>
</template>

<script>
import { storageService } from '@/services/storage.service'
//import { projectService } from '@/services/project.service'

export default {
	name: 'Panel',
	data() {
		return {
			role: 0,
			projectList: []
		};
	},
	methods: {
		setLink (link) {
			return `/waec-panel/${link}`
		},
		getProjectName () {
			let projectName = ''
			this.projectList.map((m) => {
				if(this.folder_path === m.project_folder){
					projectName = m.project_name
				}
			})
			return projectName
		},
		logout () {
			storageService.removeLogin()
			setTimeout(() => {
				this.$router.push('/')
			}, 200)
		}
	}
};
</script>

<style scoped>
header{
	display: flex;
	justify-content: flex-start;
	/*background: #5cbaea;*/
	background: #09568d;
	/*box-shadow: 0px 0px 20px 6px #1e6e96 inset;*/
	box-shadow: 0px 0px 20px 6px #76a0c3 inset;
	padding: 10px 5px;
	height: 90px;
	top: 0;
    position: fixed;
    width: 100%;
    z-index: 1000;
}
header ul li{
	display: inline-block;
	text-transform: capitalize;
	padding: 10px 7px;
	min-width: 70px;
}
header ul li .tab-box a{
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-decoration: none !important;
	align-items: center;
	text-align: center;
	/*color: #36383a;*/
	color: #fbf8f8;
	margin-right: 10px;
	font-size: 14px !important;
}
header ul li .tab-box a svg, header ul li .tab-box .btn svg{
	margin-bottom: 10px;
}header ul li .tab-box a svg, header ul li .tab-box .btn svg{
	margin-bottom: 10px;
}
@media screen and (max-width: 576px){
	header{
		height: 107px !important;
	}
	header ul{
		display: flex !important;
		overflow-x: auto !important;
		overflow-y: hidden !important;
		flex-wrap: nowrap !important;
		flex-direction: row !important;
	}
	header ul{
		padding-left: 0px !important;
	}
	header ul li{
		padding: 10px 3px !important;
		min-width: 70px !important;
	}
	header ul li .tab-box a{
		text-align: center;
		margin-right: 0px !important;
		font-size: 12px !important;
	}
}
.content-wrapper{
	background-color: #f4f5f8;/*#ebedf1;*/
	width: 100%;
	min-height: calc(100vh - 130px);
	padding-top: 40px;
	padding-bottom: 70px;
	margin-top: 130px;
}
.breadcrumb{
	padding: 1rem 3rem;
    margin-bottom: 0px !important;
    background: #3d79a7;
    color: #FFFFFF;
    font-weight: 600;
}
</style>